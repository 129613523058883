










































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  getItemData,
  deleteItem,
  updateItem,
  changeMutex,
  getMutex,
  getMutexItemList
} from '@/serve/medicalAppointment/itemsManage';
import { getDisease } from '@/serve/evaluatingBefore/evaluatingQuestion';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
@Component({
  name: 'ItemsManage',
})
export default class ItemsManage extends Vue {
  public form = {
    checkItemName: '',
    checkItemType: '',
    tenantId: '',
  };
  public page = {
    current: 1,
    size: 10,
  };

  //互斥提交数组
  public exclusiveSubmit = {
    itemId: 0,
    mutexItemIds: []
  }
  //已经选择过的互斥项数组
  public exclusiveSubmitArray = [];
  public showdialog = false;//打开互斥框
  mutually(row: any) {
    this.exclusivepage = {
      current: 1,
      size: 999,
    }
    this.exclusiveform.notItemId = row.id;
    this.idArray = [{
      page: 0,
      data: []
    }];
    //先清空再赋值
    this.exclusiveSubmit = {
      itemId: row.id,
      mutexItemIds: []
    }
    this.showdialog = true;
    this.exclusivegetItemData()
    getMutex(row.id).then(res => {
      this.exclusiveSubmitArray = res.data
    })
  }
  public formData = {
    checkItemRemark: '',
    checkItemDesc: '',
    checkItemSort: '',
    isCheckbox: false,
    id: Number,
  };
  public itemData = [];
  public total = 0;
  public diseaseData = [];
  public itemDialog = false;
  public labelPosition = 'left';
  public isAdmin = sessionStorage.getItem('isAdmin');
  public tenantName = '';
  public restaurants = [];
  public exclusiveitemData: any = [];
  public exclusivetotal = 0;
  public exclusivediseaseData = [];
  public created() {
    this.getItemData();
  }
  public mounted() {
    this.getTenantData();
  }
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = '';
  }
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  //获取项目表数据
  public async getItemData(): Promise<void> {
    const dataItem = { ...this.page, ...this.form };
    const res = await getItemData(this.$changNull(dataItem));
    this.itemData = res.data.records;
    const data = {
      diseaseType: '疾病',
    };
    //获取疾病系统的数据
    const Disease = await getDisease(data);
    this.diseaseData = Disease.data.records;
    this.itemData.forEach((ele: any, index) => {
      this.diseaseData.forEach((val: any) => {
        if (ele.diseaseId === val.id) {
          this.$set(this.itemData[index], 'diseaseName', val.diseaseName);
        }
      });
    });
    console.log(' this.itemData :>> ', this.itemData);
    this.total = res.data.total;
  }
  public exclusivepage = {
    current: 1,
    size: 999,
  }
  public exclusiveform = {
    checkItemName: '',
    checkItemType: '',
    tenantId: '',
    notItemId:'',
  };
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getItemData();
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getItemData();
  }
  //互斥获取项目表数据
  public async exclusivegetItemData(): Promise<void> {
    const dataItem = { ...this.exclusivepage, ...this.exclusiveform };
    const res = await getMutexItemList(this.$changNull(dataItem));
    this.exclusiveitemData = res.data.records;
    this.$nextTick(() => {
      this.submitSelection(this.exclusiveSubmitArray);
      for (let i = 0; i < this.idArray.length; i++) {
        console.log('进入了渲染')
        if (this.exclusivepage.current === this.idArray[i].page) {
          this.submitSelection(this.idArray[i].data);
        }
      }
    });
    // this.submitSelection(this.exclusiveSubmitArray);
    const data = {
      diseaseType: '疾病',
    };
    //获取疾病系统的数据
    const Disease = await getDisease(data);
    this.exclusivediseaseData = Disease.data.records;
    this.exclusiveitemData.forEach((ele: any, index: any) => {
      this.exclusivediseaseData.forEach((val: any) => {
        if (ele.diseaseId === val.id) {
          this.$set(this.exclusiveitemData[index], 'diseaseName', val.diseaseName);
        }
      });
    });
    this.exclusivetotal = res.data.total;
  }

  //互斥点击每页显示的条数触发获取数据
  public exclusivehandleSizeChange(val: any): void {
    this.exclusivepage.size = val;
    this.exclusivegetItemData();
  }
  //互斥点击第N页触发获取数据
  public exclusivehandleCurrentChange(val: any): void {
    this.exclusivepage.current = val;
    this.exclusivegetItemData();
  }
  //提交互斥选项
  public async handleExclusive() {
    (this as any).$messageBox
      .confirm('确定提交吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const res = changeMutex(this.exclusiveSubmit); // 调用接口
        console.log(res);
        this.showdialog = false;
      })
  }
  //存储页面选择过的数据
  public idArray = [{
    page: 0,
    data: []
  }];
  //添加数据到互斥数组中
  public SelectionChange(selection: any) {
    console.log('selection', selection);
    this.exclusiveSubmit.mutexItemIds = selection.map((item: any) => item.id);
    // const parmar={
    //   page:this.exclusivepage.current,
    //   data:selection
    // }
    // if(selection.length===0){return}
    // let bool=true
    // for(let i=0;i<this.idArray.length;i++){
    //   if(parmar.page===this.idArray[i].page){
    //     this.idArray[i].data=parmar.data;
    //     console.log('idarray',this.idArray);
    //     bool=false
    //   }
    // }
    // if(bool){
    //   this.idArray.push(parmar);
    // }
    // console.log('idarray',this.idArray);
  }

  //勾选被使用过的互斥项目 
  public submitSelection(rows: any) {
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < this.exclusiveitemData.length; j++) {
        if (rows[i].id === this.exclusiveitemData[j].id) {
          const r = this.$refs.multipleTable as any
         r.toggleRowSelection(this.exclusiveitemData[j]);
        }
      }
    }
  }

  // 搜索
  public async search() {
    const value = { ...this.page, ...this.form };
    const res = await getItemData(this.$changNull(value));
    this.itemData = res.data.records;
    this.total = res.data.total;
  }

  //多选删除
  public handleSelectionChange() {
    const list = (this.$refs.table as any).selection;
    this.deleteRow(list);
  }
  //删除
  public deleteRow(list: { checkItemNumber: any }[]): void {
    (this as any).$messageBox
      .confirm('您确定是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        const ids: number[] = [];
        list.forEach((ele) => {
          ids.push(ele.checkItemNumber);
        });
        const res = await deleteItem(ids);
        if (res.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          });
          this.getItemData();
        } else {
          this.$message.error('删除失败' + res.msg);
        }
      });
  }
  //编辑
  public editRow(list: any): void {
    this.itemDialog = true;
    this.formData.checkItemRemark = list[0].checkItemRemark;
    this.formData.checkItemDesc = list[0].checkItemDesc;
    this.formData.checkItemSort = list[0].checkItemSort;
    this.formData.isCheckbox = list[0].isCheckbox;
    this.formData.id = list[0].id;
  }
  //提交按钮
  public async handlerSubmit() {
    const { id, checkItemRemark, checkItemDesc, isCheckbox, checkItemSort } = this.formData;
    const data = {
      checkItemRemark: checkItemRemark,
      checkItemDesc: checkItemDesc,
      isCheckbox: isCheckbox,
      checkItemSort: checkItemSort,
      id: id,
    };
    const res = await updateItem(data);
    this.itemDialog = false;
    if (res.success) {
      this.$message({
        message: '项目说明、项目备注修改成功',
        type: 'success',
      });
      this.getItemData();
    } else {
      this.$message.error(res.msg);
    }
  }
  // 重置功能
  public resetForm() {
    this.tenantName = ""
    this.form = {
      checkItemName: '',
      checkItemType: '',
      tenantId: '',
    };
    this.page = {
      current: 1,
      size: 10,
    };
    this.getItemData();
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = '';
  }
}
